<template>
  <OnboardingStep
    :title="$t('onboarding.teamIntroView.title')"
    :next-text="$t('onboarding.teamIntroView.nextText')"
    v-bind="$props"
  >
    <i18n tag="p" path="onboarding.teamIntroView.schedulingSystemsInfo" />
    <div class="d-flex mb-4">
      <RaiLink :to="{ name: 'onboarding.team.whenIWork' }" class="mr-4">
        {{ $t("onboarding.teamIntroView.whenIWorkLink") }}
      </RaiLink>
      <RaiLink :to="{ name: 'onboarding.team.homebase' }">
        {{ $t("onboarding.teamIntroView.homebaseLink") }}
      </RaiLink>
    </div>
    <i18n tag="p" path="onboarding.teamIntroView.addManually" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import { RaiLink } from "@/core-ui";

export default {
  name: "TeamIntroView",
  components: { RaiLink },
  extends: BaseStepView,
};
</script>
